<template>
  <div class="home">
    <div class="vid">
      <video id="video" src="../assets/background1.mp4" autoplay="true" preload="auto" loop muted></video>
      <div class="bleu"></div>
    </div>
     <vue-particles
        color="#ffffff"
        :particleOpacity="0.2"
        :particlesNumber="100"
        shapeType="circle"
        :particleSize="10"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.5"
        :linesDistance="150"
        :moveSpeed="6"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>

      <div class="logo">
        <img src="../assets/logo.png" alt="">
      </div>

      <div class="conteneur">
        <h1><span class="orange">Ordi</span>Bessin</h1>
        <h2>Dépannage et services informatiques</h2>
      </div>

      <div class="about">
        <p><span id="aboutP"></span><span id="clignote">|</span></p>
        <p><br>Fort de nos {{ nbAnnee }} ans d'expérience (en activité depuis 2001), nous intervenons rapidement pour un dépannage efficace. Nous restons un service de proximité adapté aux petites entreprises, au collectivités locales, et aux particuliers.</p>
        <p><br>Quelques sois vos problèmes informatiques, PC instable, connexion Internet récalcitrante, virus ou piratage, un conseil ou une formation d'initiation, nous mettrons toute notre expertise professionnelle pour vous dépanner et répondre à vos demandes.</p>
        
        <div class="alert">
          <h4 class="alert-heading">Cette année, nous nous diversifions !</h4>
          <p>
            Nous lançons notre activité de <span class="orangeFonce">Création de Sites Internet</span>. <br>
            Nous proposons un choix de sites simples, légers et fonctionnels à des prix très attractifs.<br>
            D'une formule économique qui sait rester modulable et entierement personnalisable à un site sur mesure, exclusif et original, élaboré pour refléter la personnalité et les valeurs de votre société, obtenez un site lisible, clair et ... à partir de 499 euros
          </p>
          <hr>
          <p>Découvrez nos réalisations en cliquant <router-link to="/creationSites">ici</router-link>.</p>
        </div>
      </div>

  </div>
</template>

<style scoped>
.vid{
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: -100;
}

.home >>> .particles-js-canvas-el{
  position: fixed;
  z-index: -90;
}

.logo{
  position: absolute;
  top:5%;
  margin-left: 7%;
  width: 200px;
}

.logo img{
  width: 100%;
}

h1{
	color: white;
	font-family: "xenotron";
	font-size: 70px;
	text-shadow: 8px 8px 6px black;
  position: absolute;
  top:300px;
  margin-left: 7%;
  animation: adroite 2s;
}

.orange{
	color:orange;
}

.orangeFonce{
  color:rgb(218, 141, 0);
  font-weight: bold;
}

h2{
  color: white;
	font-family: "jura";
  font-size: 40px;
  position: absolute;
  top: 400px;
  margin-left: 7%;
  animation: agauche 2s;
}

.about{
  width: 60%;
  color: white;
	font-family: "jura";
  font-size: 20px;
  position: absolute;
  top: 650px;
  left: 10%;
  overflow-x: visible;
  min-height: 80vh;
}

#clignote  {
  font-size: 30px;
  animation-duration: .8s;
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
}

.alert {
  text-align: center;
  width: 80%;
	background-color: #CCE5FF;
	border: solid 2px #B8DAFF;
	border-radius: 5px;
	color: #004085;
  position: absolute;
  margin: 20px auto;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;
}

.alert-heading {
	font-size: 25px;
  margin-bottom: 20px;
}

.alert hr {
	border: none;
	background-color: #A8D1FF;
	height: 2px;
	width: 90%;
  margin: 10px auto;
}

@keyframes clignoter {
  0%   { opacity:1; }
  50%   {opacity:0; }
  100% { opacity:1; }
}

@media(max-width:630px){
  .logo{
    top:10%;
    margin-left: auto;
    width: 100%;
    text-align: center;
  }

  .logo img{
    width: 200px;
  }

  .about{
    width: 80%;
    left: inherit;
    padding: 0 25px 50px;
  }

  #particles-js{
    display: none;
  }
}

@media(max-width:550px){

  h1{
		font-size: 38px;
	}
	
	h2{
		font-size: 30px;
	}
	
}

@keyframes adroite {
  0% { 
    transform: translateX(100%);
    opacity: 0;
  }

	30% {
    opacity: 0;
  }

  100% {
     transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes agauche {
  0% { 
    transform: translateX(-100%);
    opacity: 0;
  }

	30% {
    opacity: 0;
  }

  100% {
     transform: translateX(0%);
    opacity: 1;
  }
}


</style>

<script>
export default {
  name: 'Home',
  data() {
    return {
      text: `Situés à Tour en Bessin, nous assurons un service Informatique sur Bayeux et sa région, pour les professionnels et les particuliers.`,
      i: 0,
      speed: 15,
      nbAnnee: null
    }
  },
  mounted() {
    document.getElementById("aboutP").innerHTML = "";
    this.typeWriter();

    const dateExp = new Date('January 1, 2001 00:00:00');
    var ageDifMs = Date.now() - dateExp.getTime();
    var ageDate = new Date(ageDifMs);
    this.nbAnnee = Math.abs(ageDate.getUTCFullYear() - 1970);
    window.scrollTo(0, 0);
  },
  methods: {
    typeWriter() {
      if (this.i < this.text.length) {
        document.getElementById("aboutP").innerHTML += this.text.charAt(this.i);
        this.i++;
        setTimeout(this.typeWriter, this.speed);
      }
    }
  },
}
</script>
