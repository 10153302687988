import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/prestations',
    name: 'Prestations',
    component: () => import('../views/Prestations.vue')
  },
  {
    path: '/vente',
    name: 'Vente',
    component: () => import('../views/EnConstruction.vue')
  },
  {
    path: '/creationSites',
    name: 'CreationSites',
    component: () => import('../views/CreationSites.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/formuleEssentielle',
    name: 'FormuleEssentielle',
    component: () => import('../views/FormuleEssentielle.vue')
  },
  {
    path: '/formulePremium',
    name: 'FormulePremium',
    component: () => import('../views/FormulePremium.vue')
  },
  {
    path: '/formuleSurMesure',
    name: 'FormuleSurMesure',
    component: () => import('../views/EnConstruction.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
